"use client";

import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";

export const ModalContext = React.createContext({
  content: undefined as ReactNode,
  setModal: (node: ReactNode) => {},
  close: () => {},
});

export const WithModal = ({ children }: { children: React.ReactNode }) => {
  const [content, setContent] = useState<ReactNode>(null);

  const setModal = useCallback((node: ReactNode) => {
    setContent(node);
  }, []);

  const close = useCallback(() => {
    setModal(null);
  }, [setModal]);

  // make body not scrollable when modal is open
  // we can't set this class directly on the <body> element without
  // blocking server-side rendering of the entire layout.
  useEffect(() => {
    const body = document.querySelector("body");
    if (!body) {
      return;
    }
    if (content) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }, [content]);

  return (
    <ModalContext.Provider
      value={{
        content,
        setModal,
        close,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const Modal = () => {
  const { content, close } = useContext(ModalContext);
  if (!content) {
    return null;
  }

  return (
    <>
      <div
        className={[
          "fixed inset-0 p-4 min-h-dvh w-full", // fullscreen
          "flex items-center justify-center", // center children
          "z-40 bg-black bg-opacity-20 backdrop-blur-[3px]", // fade background
        ].join(" ")}
        onClick={close}
      >
        <div
          className="bg-white px-4 py-3 rounded-lg shadow-lg relative"
          onClick={(e) => e.stopPropagation()} // avoid closing when clicking inside the modal
        >
          <button
            className="absolute rounded-full bg-white shadow right-0 top-0 mr-[-0.75rem] mt-[-0.75rem] p-1 z-10"
            type="button"
            onClick={close}
          >
            <XMarkIcon className="w-4 h-4" />
          </button>
          <div className="max-h-[80dvh] overflow-y-auto">{content}</div>
        </div>
      </div>
    </>
  );
};
